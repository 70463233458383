


























import { Component, Vue } from "vue-property-decorator";
import BlogDataContext from "@/dataContexts/BlogDataContext";
import BlogPostComponent from "@/components/blog/BlogPostComponent.vue";
import Spinner from "@/components/SpinnerComponent.vue";
import RequestResult from "@/common/models/RequestResult";
import { BlogPost } from "@sokkuri/common";

@Component({
    components: {
        Spinner,
        BlogPostComponent
    }
})
export default class Blog extends Vue {
    private dataContext: BlogDataContext = new BlogDataContext();
    private posts: Array<BlogPost> = [];
    private loading = false;

    created() {
        this.loading = true;
        this.dataContext.getBlogPosts().then((result: RequestResult<Array<BlogPost>>) => {
            if (result.successfully && result.data) {
                this.posts = result.data;
            }

            this.loading = false;
        });
    }
}
