















import { Component, Prop, Vue } from "vue-property-decorator";
import ImageComponent from "@/components/global/ImageComponent.vue";
import TimeFormatUtils from "@/common/utilities/TimeFormatUtils";

@Component({
  components: {
    ImageComponent
  }
})
export default class BlogPostComponent extends Vue {
    @Prop() private imageUrl!: string;
    @Prop() private url!: string;
    @Prop() private title!: string;
    @Prop() private content!: string;
    @Prop() private creationDate!: string;

    private getFormattedDate() {
    return TimeFormatUtils.getDateTime(this.creationDate);
    }
}
