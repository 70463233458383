










import { Component, Vue } from "vue-property-decorator";
import Blog from "@/components/blog/BlogComponent.vue";

@Component({
  components: {
    Blog
  }
})
export default class HomeView extends Vue {
}
